
import Vue from 'vue';
import { Component, Prop, Watch } from 'vue-property-decorator';
import ViewContent from '@/components/ViewContent.vue';
import { ConfigType, DynamicFulfillmentState, Environments, IConfig, IConfigHistory, RouteAction, RoutingAction, } from '@/store/dynamicfulfillment/types';
import EventBus from '@/common/event.bus';
import XLSX from 'xlsx';
import { State } from 'vuex-class';
import PaginationPageSizeControl from '@/components/common/PaginationPageSizeControl.vue';
import FilterOnFields from '@/components/common/FilterOnFields.vue';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import ConfigHistoryModalComponent from '@/components/configuration/ConfigHistoryModalComponent.vue';
import { buildErrorMessage } from '@/common/functions.helpers';
const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, FilterOnFields, PaginationPageSizeControl, VueJSONEditor, ConfigHistoryModalComponent } })
export default class ConfigIndexComponent extends Vue {
  @Prop() title: string;
  @Prop() indexRoute!: string | { name: string, query?: any };
  @Prop() refreshAction: string;
  @Prop() fields: any[];
  @Prop() initialSort: string;
  @Prop({ default: false }) private sortDesc: boolean;
  @Prop({
    type: Array,
    required: false,
    default: (): any => {
      return ['_attachments'];
    },
  })
  ignoredFields: any[];
  @Prop({ default: false }) isLazyLoad: boolean;
  @Prop({ type: Number, default: 500 }) debounce: number;
  @Prop({ type: Function, default: undefined }) filterFunction: (row: any, filter: string) => boolean;
  @Prop({ default: null }) private mappedConfigs: any[];
  @Prop() exportFields: any[];
  @Prop() exportFileName: string;
  @Prop() routeActions: RouteAction[];
  @Prop({default: ConfigType.None}) configType: ConfigType;
  @State(namespace) profile!: DynamicFulfillmentState;

  filterOnFields: any[] = [];
  configs: IConfig[] = null;
  configsHistory: IConfigHistory[] = null;
  filter: string = '';
  currentPage = 1;
  pageSize = 10;
  stickyHeaderSize = '625px';
  totalRows = 1;
  sortBy = '';
  isFetchingAdditionalRows: boolean = false;
  sortByDesc: boolean = false;
  routingAction = RoutingAction;
  currentPageSize = 10;  
  configHistory: IConfigHistory = {
    _etag: null,
    id: '',
    history: [],
  };  

  async mounted() {
    this.sortByDesc = this.sortDesc;
    await this.load();
  }

  async load() {
    const fetchAction = this.getAction(RoutingAction.Fetch);
    this.configs = await this.$store.dispatch(fetchAction.action, fetchAction.params);
    this.totalRows = this.configs.length;
    this.sortBy = this.initialSort;
    this.isFetchingAdditionalRows = false;
    this.$emit('pageLoaded', this.configs);
  }

  get showActions() {
    return !!this.canAction(RoutingAction.Create) || !!this.canAction(RoutingAction.Upload);
  }

  get canEditRow() {
    return !!this.canAction(RoutingAction.Edit) || this.canAction(RoutingAction.Remove);
  }

  getAction(name: string): RouteAction {    
    return this.routeActions.find((i) => i.name === name);
  }

  onFiltered(filteredItems: IConfig[]) {
    this.totalRows = filteredItems.length;
    this.currentPage = 1;
  }

  get allFields() {
    const fields = [...this.fields];
    if (this.hasUserAuditHistory) {
      fields.push(
        {
          key: 'userAudit.current.name',
          label: 'Last Modified User',
          sortable: true,
          filterable: true,
        },
        {
          key: 'userAudit.current.modifiedOnUtc',
          label: 'ModifiedOn Utc',
          sortable: true,
          filterable: true,
          formatter: (value: any, key: any, item: any) => {
            return value === '0001-01-01T00:00:00' ? '' : value;
          },
        },
      );
    }

    fields.push('actions');

    return fields;
  }

  get hasUserAuditHistory() {
    return this.configs.some((x) => x.userAudit);
  }

  async remove(config: any) {
    this.$bvModal
      .msgBoxConfirm('Are you sure? This cannot be undone.', {
        title: ``,
        size: 'sm',
        buttonSize: 'sm',
        okVariant: 'danger',
        okTitle: 'YES',
        cancelTitle: 'NO',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true,
      })
      .then(async (value) => {
        if (value) {
          const removeAction = this.getAction(RoutingAction.Remove);
          const fetchAction = this.getAction(RoutingAction.Fetch);          
          let params = null;
          if(removeAction?.params) { 
            removeAction.params.config = config; 
            params = removeAction.params;
          } else {
            params = { config };
          }
          await this.$store.dispatch(removeAction.action, params).then(async () => {
            this.configs = await this.$store.dispatch(fetchAction.action, fetchAction.params);
          });
          EventBus.$emit('s', `${config.id} deleted successfully`);
        }
      })
      .catch((err) => {
        // An error occurred
        this.displayError(err);
      });
  }

  async download() {
    this.$emit('mapConfig', this.configs);
  }

  canAction(name: string, id?: string): boolean {
    const routeAction = this.routeActions?.find((routeAction) => routeAction.name === name);

    if (routeAction) {
      const hasPermission = userAuthorizationProviderFactory(this.profile).hasPermission([routeAction.requiredPermission]);

      if (!!id && !!routeAction.exclusion) {
        return !routeAction.exclusion(id) && hasPermission;
      }

      return hasPermission;
    }

    return false;
  }

  @Watch('filterOnFields', { immediate: false, deep: true })
  filterOnFieldsChanged() {
    this.$emit('filterOnFieldsChanged', this.filterOnFields);
  }

  @Watch('mappedConfigs', { immediate: false, deep: true })
  mappedConfigChange() {
    const worksheet = XLSX.utils.json_to_sheet(this.mappedConfigs, {
      header: this.exportFields,
    });
    const newWorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(newWorkBook, worksheet, 'WorkSheet1');
    XLSX.writeFile(newWorkBook, `${this.exportFileName}`);
  }

  async refresh() {
    this.configs = await this.$store.dispatch(this.getAction(RoutingAction.Refresh).action);
    this.load();
  }

  @Watch('isLazyLoad', { immediate: false })
  async fetchAdditionalRecords() {
    this.isFetchingAdditionalRows = true;
    await this.load();
  }
  filterOnFieldsHasChanged(filterOnFields: string[]) {
    this.filterOnFields = filterOnFields;
  }

  @Watch('pageSize', { immediate: true })
  updatePageSize(newValue: any, oldValue: any) {
    this.pageSizeChanged(newValue);
  }

  pageSizeChanged(pageSize: any) {
    this.currentPageSize = pageSize;
  }

  get isValidConfigType() {
    return this.configType !== ConfigType.None;
  }

  @Watch('configs', {immediate: true})
  updateConfigsHistory(newValue: IConfig[], oldValue: IConfig[]) {
    this.configsHistory = JSON.parse(JSON.stringify(this.configs));
  }

  get historyModalId() {
    return (id: string) => `${id}-history`;
  }

  get historyConfigModalId() {
    return (id: string) => `${id}-history-config`;
  }

  get historyConfigModalTitle() {
    return (item: IConfig) => `${item.userAudit.current.name}-${item.userAudit.current.modifiedOnUtc}`;
  }

  showHistory(item: IConfig) {
    let historyItem = this.configsHistory.find((x) => x.id === item.id);
    if(historyItem.history) { 
      this.history = historyItem.history;     
      this.$bvModal.show(this.historyModalId(item.id));
      return;
    }
    const historyFetch = this.getAction(RoutingAction.History);
    this.$store.dispatch(historyFetch.action, {configType: this.configType, id: item.id}).then((response: IConfig[]) => {
      historyItem.history = response;
      this.history = response;
      this.$bvModal.show(this.historyModalId(item.id));
    });
  }  

  hideHistory(item: IConfig) {    
    this.$bvModal.hide(this.historyModalId(item.id)); 
    this.load();
  }


  set history(value: IConfig[]) {
    this.configHistory.history = value;
  }

  get history() {
    return this.configHistory.history.sort((a, b) => {
            return a.userAudit.current.modifiedOnUtc < b.userAudit.current.modifiedOnUtc ? 1 : -1;
        });
  }

  isValidEnvironment(): boolean {
    return process.env.VUE_APP_ENVIRONMENT == Environments.LocalHost || process.env.VUE_APP_ENVIRONMENT == Environments.Development || process.env.VUE_APP_ENVIRONMENT == Environments.Model; 
  }

  isEligibleToView(): boolean {
    return userAuthorizationProviderFactory(this.profile).hasAdminRole() && this.isValidEnvironment();
  }

  displayError(error: any) {
      const message = buildErrorMessage(error);
      EventBus.$emit('e', `Request failed. ${message}`);
  }
}
