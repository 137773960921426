
import Vue from 'vue';
import { Component, Prop } from 'vue-property-decorator';
import ConfigIndexComponent from '@/components/configuration/ConfigIndexComponent.vue';
import { FETCH_SOURCE_SYSTEM_PROGRAM_CONFIGS, DELETE_PROGRAM, FETCH_CONFIG_HISTORY, PROGRAM_CONFIG_SAVE  } from '@/store/constants';
import { ConfigType, FilterDefinition, FilterType, FulfillmentOption, ProgramConfigVersion, ProgramFulfillmentOptionConfig, RoutingAction, ProgramConfig } from '@/store/dynamicfulfillment/types';
import ProductConfigModal from '../../../components/configuration/ProductConfigModal.vue';
import VueJSONEditor from '@/components/VueJSONEditor.vue';
import { jsonToText } from '@/common/functions.helpers';
import PriceListConfigModal from '@/components/configuration/PriceListConfigModal.vue';
import DataTable from '@/components/common/DataTable.vue';
@Component({
  components: {
    ConfigIndexComponent,
    ProductConfigModal: ProductConfigModal,
    VueJSONEditor,
    PriceListConfigModal,
    DataTable,
  },
})
export default class ProgramConfigIndex extends Vue {
  @Prop() private sourceSystem: string;
  configType: ConfigType = ConfigType.Programs;  
  jsonEditorText: any = jsonToText;
  filterOnFields: string[] = [];

  fields: any[] = [
    {
      key: 'id',
      thStyle: { width: '325px' },
      sortable: true,
      filterable: true,
    },
    {
      key: 'name',
      sortable: true,
      filterable: true,
    },
    {
      key: 'productIds',
      thStyle: {
        width: '325px',
      },
      filterable: true,
    },
    {
      key: 'currentFulfillmentOptions',
      label: 'Current Fulfillment Options',
      formatter: (value: any, key: any, item: ProgramConfig) => {
        const currentVersion = item.programConfigVersions.filter( (configVersion: ProgramConfigVersion) => item.activeVersion === configVersion.version)[0];
        var fulfillmentOptions = currentVersion.fulfillmentOptions.map((fulfillmentOptionConfig: ProgramFulfillmentOptionConfig) => {
          return fulfillmentOptionConfig.name;
        });
        return fulfillmentOptions.join('<br />');
      },
      filterable: true,
      filterByFormatted: true,
    },
    'activeVersion',
    'versionCount',
    { 
      key: 'pricelists',
      label: 'View Price List Configs',
      thStyle: {
        width: '100px',
      },
    },
    {
      key: 'details',
      thStyle: {
        width: '90px',
      },
    },
  ];

  programFulfillmentOptionFields: any[] = [
    {
      key: 'fulfillmentOption',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
    {
      key: 'serviceProviders',
    },
    {
      key: 'useLegacy',
    },
    {
      key: 'distanceToSearch',
    },
    {
      key: 'numberOfRecordsToReturn',
    },
    {
      key: 'receiptRequired',
    },
    {
      key: 'isPartsOrderingEligible',
    },
    {
      key: 'salvageStrategy',
    },
  ];

  fulfillmentOptionServiceProvidersFields: any[] = [
    {
      key: 'fulfillmentOption',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
    {
      key: 'serviceProviders',
    },
  ];

  fulfillmentOptionFilterDefinitionFields: any[] = [
    {
      key: 'fulfillmentOption',
      formatter: (value: any, key: any, item: any) => {
        return FulfillmentOption[value];
      },
    },
    {
      key: 'filterType',
      formatter: (value: any, key: any, item: any) => {
        return FilterType[value];
      },
    },
    {
      key: 'filterName',
    },
    {
      key: 'filterDescription',
    },
  ];

  programFilterDefinitionFields: any[] = [
    {
      key: 'filterType',
      formatter: (value: any, key: any, item: any) => {
        return FilterType[value];
      },
    },
    {
      key: 'filterName',
    },
    {
      key: 'filterDescription',
    },
  ];

  configModal: any = {
    id: '',
    title: '',
    content: '',
  };

  config(item: any, index: any, button: any) {
    this.configModal.id = item.id;
    this.configModal.title = `Program Config`;
    this.configModal.content = item;
    this.$root.$emit('bv::show::modal', this.configModal.id, button);
  }

  resetConfigModal() {
    this.configModal.title = '';
    this.configModal.content = '';
  }

  get routeActions() {
    return [
      { name: RoutingAction.Edit, route: 'ProgramConfig-Edit', requiredPermission: 'Configuration-Programs-Edit', params: { sourceSystem: this.sourceSystem } },
      { name: RoutingAction.Create, route: 'ProgramConfig-Create', requiredPermission: 'Configuration-Programs-Create' },
      { name: RoutingAction.Fetch, action: FETCH_SOURCE_SYSTEM_PROGRAM_CONFIGS, requiredPermission: 'Configuration-Programs-View', params: { sourceSystem: this.sourceSystem } },
      { name: RoutingAction.Remove, action: DELETE_PROGRAM, requiredPermission: 'Configuration-Programs-Delete' },
      { name: RoutingAction.History, action: FETCH_CONFIG_HISTORY, requiredPermission: 'Configuration-Programs-View' },
      { name: RoutingAction.Revert, action: PROGRAM_CONFIG_SAVE, requiredPermission: 'Configuration-Programs-Edit' },
      { name: RoutingAction.RemovedItems, route: 'RemovedProgramConfig-Index', requiredPermission: 'Global-HardDelete', params: { sourceSystem: this.sourceSystem } },
    ];
  }

  priceListConfigModal(id: string) {
    return `PriceList-${id}`;
  }

  get programFulfillmentOptionConfigs() {
    return (data: any) => {
      const currentVersion = data.programConfigVersions.filter((configVersion: ProgramConfigVersion) => data.activeVersion === configVersion.version)[0];
      if (currentVersion) {
        return currentVersion.fulfillmentOptions;
      }
      return [];
    };
  }

  get fulfillmentOptionFilterDefinitions() {
    return (data: any) => {
      const fulfillmentOptionFilterDefinitions: {
        fulfillmentOption: FulfillmentOption;
        filterType: number;
        filterName: string;
        filterDescription: string;
      }[] = [];
      const currentVersion = data.programConfigVersions.filter((configVersion: ProgramConfigVersion) => data.activeVersion === configVersion.version)[0];
      currentVersion.fulfillmentOptions.forEach((fo: ProgramFulfillmentOptionConfig) =>
        fo.filterDefinitions.forEach((fd: FilterDefinition) => {
          fulfillmentOptionFilterDefinitions.push(
            ...[
              {
                fulfillmentOption: fo.fulfillmentOption,
                filterType: fd.type,
                filterName: fd.name,
                filterDescription: fd.description,
              },
            ],
          );
        }),
      );
      return fulfillmentOptionFilterDefinitions;
    };
  }

  get programFilterDefinitions() {
    return (data: any) => {
      const programFilterDefinitions: {
        filterType: number;
        filterName: string;
        filterDescription: string;
      }[] = [];
      const currentVersion = data.programConfigVersions.filter((configVersion: ProgramConfigVersion) => data.activeVersion === configVersion.version)[0];
      if (currentVersion && currentVersion.filterDefinitions) {
        currentVersion.filterDefinitions.forEach((fd: FilterDefinition) =>
          programFilterDefinitions.push(
            ...[
              {
                filterType: fd.type,
                filterName: fd.name,
                filterDescription: fd.description,
              },
            ],
          ),
        );
      }
      return programFilterDefinitions;
    };
  }

  get offerEnhancers() {
    return (data: any) => {
      const currentVersion = data.programConfigVersions.filter((configVersion: ProgramConfigVersion) => data.activeVersion === configVersion.version)[0];
      if (currentVersion && currentVersion.offerEnhancers) {
        return currentVersion.offerEnhancers.join('<br />');
      }
      return '';
    };
  }

  protected getSourceSystem(): string {
    return this.sourceSystem;
  }
}
