import { RouteConfig } from 'vue-router';
import ConfigBaseView from '@/views/configuration/ConfigBaseView.vue';
import SourceSystemIndex from '@/views/configuration/SourceSystems/SourceSystemIndex.vue';
import SourceSystemConfigEdit from '@/views/configuration/SourceSystems/SourceSystemConfigEdit.vue';
import SourceSystemConfigDetails from '@/views/configuration/SourceSystems/SourceSystemConfigDetails.vue';
import SourceSystemConfigDetailsCreateEdit from '@/views/configuration/SourceSystems/SourceSystemConfigDetailsCreateEdit.vue';
import FulfillmentOptionConfigRoutes from '@/router/fulfillment.option.config.routes';
import ProgramConfigIndex from '@/views/configuration/Programs/ProgramConfigIndex.vue';
import ProgramConfigCreate from '@/views/configuration/Programs/ProgramConfigCreate.vue';
import ProgramConfigEdit from '@/views/configuration/Programs/ProgramConfigEdit.vue';
import RemovedProgramConfigIndex from '@/views/configuration/Programs/RemovedProgramConfigIndex.vue';
import StatusMappingIndex from '@/views/configuration/StatusMapping/StatusMappingIndex.vue';
import StatusMappingDetails from '@/views/configuration/StatusMapping/StatusMappingDetails.vue';
import StatusMappingEdit from '@/views/configuration/StatusMapping/StatusMappingEdit.vue';
import StatusMappingCreate from '@/views/configuration/StatusMapping/StatusMappingCreate.vue';
import StatusMappingUpload from '@/views/configuration/StatusMapping/StatusMappingUpload.vue';
import RemovedStatusMappingIndex from '@/views/configuration/StatusMapping/RemovedStatusMappingIndex.vue';
import ProductsIndex from '@/views/configuration/Products/ProductsIndex.vue';
import ProductsCreate from '@/views/configuration/Products/ProductsCreate.vue';
import ProductsEdit from '@/views/configuration/Products/ProductsEdit.vue';
import RemovedProductsIndex from '@/views/configuration/Products/RemovedProductsIndex.vue';
import DynamicIndex from '@/views/configuration/DynamicIndex.vue';
import store from '../store';
import { INIT_CONFIG } from '@/store/constants';
import VendorConfigIndex from '@/views/configuration/Vendors/VendorConfigIndex.vue';
import VendorConfigCreate from '@/views/configuration/Vendors/VendorConfigCreate.vue';
import VendorConfigEdit from '@/views/configuration/Vendors/VendorConfigEdit.vue';
import RemovedVendorConfigIndex from '@/views/configuration/Vendors/RemovedVendorConfigIndex.vue';
import LocaleConfigIndex from '@/views/configuration/Locales/LocaleConfigIndex.vue';
import LocaleConfigEdit from '@/views/configuration/Locales/LocaleConfigEdit.vue';
import AppConfigIndex from '@/views/configuration/AppConfigs/AppConfigIndex.vue';
import AppConfigCreate from '@/views/configuration/AppConfigs/AppConfigCreate.vue';
import AppConfigEdit from '@/views/configuration/AppConfigs/AppConfigEdit.vue';
import RolesIndex from '@/views/configuration/Roles/RolesIndex.vue';
import RolesCreate from '@/views/configuration/Roles/RolesCreate.vue';
import RolesEdit from '@/views/configuration/Roles/RolesEdit.vue';
import RemovedRolesIndex from '@/views/configuration/Roles/RemovedRolesIndex.vue';
import PriceListConfigRoutes from '@/router/pricelist.config.routes';
import ClearCache from '@/views/configuration/ClearCache.vue';
import UserConfigIndex from '@/views/configuration/Users/UserConfigIndex.vue';
import UserConfigCreate from '@/views/configuration/Users/UserConfigCreate.vue';
import UserConfigEdit from '@/views/configuration/Users/UserConfigEdit.vue';
import UserConfigCopy from '@/views/configuration/Users/UserConfigCopy.vue';
import UserConfigUpload from '@/views/configuration/Users/UserConfigUpload.vue';
import RemovedUserConfigIndex from '@/views/configuration/Users/RemovedUserConfigIndex.vue';
import EquipmentExclusionConfigIndex from '@/views/configuration/EquipmentConfigs/EquipmentExclusionConfigIndex.vue';
import EquipmentExclusionItemsIndex from '@/views/configuration/EquipmentConfigs/EquipmentExclusionItemsIndex.vue';
import EquipmentExclusionConfigEdit from '@/views/configuration/EquipmentConfigs/EquipmentExclusionConfigEdit.vue';
import EquipmentExclusionConfigCreate from '@/views/configuration/EquipmentConfigs/EquipmentExclusionConfigCreate.vue';
import EquipmentExclusionItemEdit from '@/views/configuration/EquipmentConfigs/EquipmentExclusionItemEdit.vue';
import EquipmentExclusionItemCreate from '@/views/configuration/EquipmentConfigs/EquipmentExclusionItemCreate.vue';
import EquipmentExclusionItemsUpload from '@/views/configuration/EquipmentConfigs/EquipmentExclusionItemsUpload.vue';
import RemovedEquipmentExclusionConfigIndex from '@/views/configuration/EquipmentConfigs/RemovedEquipmentExclusionConfigIndex.vue';
import ProgramFeesConfigIndex from '@/views/configuration/ProgramFees/ProgramFeesConfigIndex.vue';
import ProgramFeesConfigCreate from '@/views/configuration/ProgramFees/ProgramFeesConfigCreate.vue';
import ProgramFeesConfigEdit from '@/views/configuration/ProgramFees/ProgramFeesConfigEdit.vue';
import RemovedProgramFeesConfigIndex from '@/views/configuration/ProgramFees/RemovedProgramFeesConfigIndex.vue';
import { DynamicFulfillmentState } from '@/store/dynamicfulfillment/types';
import { userAuthorizationProviderFactory } from '@/common/userAuthorization.api';
import AncillaryServiceTypeConfigIndex from '@/views/configuration/ServiceTypes/AncillaryServiceTypeConfigIndex.vue';
import AncillaryServiceTypeConfigCreate from '@/views/configuration/ServiceTypes/AncillaryServiceTypeConfigCreate.vue';
import AncillaryServiceTypeConfigEdit from '@/views/configuration/ServiceTypes/AncillaryServiceTypeConfigEdit.vue';
import ServiceNetworkFulfillmentOptionTypeConfigIndex from '@/views/configuration/ServiceNetworkFulfillmentOptionTypes/ServiceNetworkFulfillmentOptionTypeConfigIndex.vue';
import ServiceNetworkFulfillmentOptionTypeConfigCreate from '@/views/configuration/ServiceNetworkFulfillmentOptionTypes/ServiceNetworkFulfillmentOptionTypeConfigCreate.vue';
import ServiceNetworkFulfillmentOptionTypeConfigEdit from '@/views/configuration/ServiceNetworkFulfillmentOptionTypes/ServiceNetworkFulfillmentOptionTypeConfigEdit.vue';
import RemovedServiceNetworkFulfillmentOptionTypeConfigIndex from '@/views/configuration/ServiceNetworkFulfillmentOptionTypes/RemovedServiceNetworkFulfillmentOptionTypeConfigIndex.vue';


const namespace: string = 'dynamicfulfillmentmodule';

const routes = [
  {
    path: '/config',
    meta: { requiresAuth: true },
    component: ConfigBaseView,
    children: [
      {
        path: 'clear-cache',
        name: 'ClearCache',
        component: ClearCache,
        meta: {
          title: 'Clear Cache',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Cache-View'],
        },
      },
      {
        path: 'source-systems',
        name: 'SourceSystems',
        props: true,
        meta: {
          title: 'Source Systems',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-SourceSystems-View'],
        },
        component: SourceSystemIndex,
      },
      {
        path: 'products',
        component: DynamicIndex,
        children: [
          {
            path: ':sourceSystem',
            name: 'Products-Index',
            props: true,
            meta: {
              title: 'Products',
              requiresAuth: true,
              authorizedPermissions: ['Configuration-Products-View'],
            },
            component: ProductsIndex,
          },
          {
            path: ':sourceSystem/create',
            name: 'Products-Create',
            props: true,
            meta: {
              title: 'Products Create',
              requiresAuth: true,
              authorizedPermissions: ['Configuration-Products-Create'],
            },
            component: ProductsCreate,
          },
          {
            path: ':sourceSystem/:id/edit',
            name: 'Products-Edit',
            props: true,
            meta: {
              title: 'Products Edit',
              requiresAuth: true,
              authorizedPermissions: ['Configuration-Products-Edit'],
            },
            component: ProductsEdit,
          },
          {
            path: ':sourceSystem/removed',
            name: 'RemovedProducts-Index',
            props: true,
            meta: {
              title: 'Removed Products',
              requiresAuth: true,
              authorizedPermissions: ['Global-HardDelete'],
            },
            component: RemovedProductsIndex,
          },
        ],
      },
      {
        path: 'source-systems/:id/edit',
        name: 'SourceSystemConfig-Edit',
        props: true,
        meta: {
          title: 'Source System Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-SourceSystems-Edit'],
        },
        component: SourceSystemConfigEdit,
      },
      {
        path: 'source-systems/:id/details',
        name: 'SourceSystemConfig-Details',
        props: true,
        meta: {
          title: 'Source System Details',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-SourceSystems-View'],
        },
        component: SourceSystemConfigDetails,
      },
      {
        path: 'source-systems/:sourceSystem/:id/edit',
        name: 'SourceSystemConfigDetails-CreateEdit',
        props: true,
        meta: {
          title: 'Source System Details Create/Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-SourceSystems-Edit'],
        },
        component: SourceSystemConfigDetailsCreateEdit,
      },
      {
        path: 'programs',
        component: DynamicIndex,
        beforeEnter: checkSourceSystemPermission,
        children: [
        {
          path: ':sourceSystem',
          name: 'ProgramConfig-Index',
          props: true,
          meta: {
            title: 'Program Configs',
            requiresAuth: true,
            authorizedPermissions: ['Configuration-Programs-View'],
          },
          component: ProgramConfigIndex,
        },
        {
          path: ':sourceSystem/create',
          name: 'ProgramConfig-Create',
          props: true,
          meta: {
            title: 'Program Config Create',
            requiresAuth: true,
            authorizedPermissions: ['Configuration-Programs-Create'],
          },
          component: ProgramConfigCreate,
        },
        {
          path: ':sourceSystem/:id/edit',
          name: 'ProgramConfig-Edit',
          props: true,
          meta: {
            title: 'Program Config Edit',
            requiresAuth: true,
            authorizedPermissions: ['Configuration-Programs-Edit'],
          },
          component: ProgramConfigEdit,
        },
          {
            path: ':sourceSystem/removed',
            name: 'RemovedProgramConfig-Index',
            props: true,
            meta: {
              title: 'Removed Program Configs',
              requiresAuth: true,
              authorizedPermissions: ['Global-HardDelete'],
            },
            component: RemovedProgramConfigIndex,
          },
        ]
      },
      {
        path: 'vendor-ticket-status-mapping',
        name: 'vendorticketstatusmapping',
        props: {
          statusType: 'vendorticketstatus'
        },
        meta: {
          title: 'Vendor Ticket Status Mapping',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-VendorTicketStatusMapping-View'],
        },
        component: StatusMappingIndex,
      },
      {
        path: 'vendor-ticket-status-mapping-details',
        name: 'VendorTickeStatusMapping-Details',
        props: true,
        meta: {
          title: 'Vendor Ticket Status Mapping Details',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-VendorTicketStatusMapping-View'],
        },
        component: StatusMappingDetails,
      },
      {
        path: 'vendor-ticket-status-mapping/:id/edit',
        name: 'VendorTicketStatusMapping-Edit',
        props: true,        
        meta: {
          title: 'Vendor Ticket Status Mapping Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-VendorTicketStatusMapping-Edit'],
        },
        component: StatusMappingEdit,
      },
      {
        path: 'vendor-ticket-status-mapping/create',
        name: 'VendorTicketStatusMapping-Create',
        props: {
          statusType: 'vendorticketstatus'
        },
        meta: {
          title: 'Vendor Ticket Status Mapping Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-VendorTicketStatusMapping-Create'],
        },
        component: StatusMappingCreate,
      },
      {
        path: 'vendor-ticket-status-mapping/upload',
        name: 'VendorTicketStatusMapping-Upload',
        props: true,
        meta: {
          title: 'Vendor Ticket Status Mapping Upload',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-VendorTicketStatusMapping-Upload'],
        },
        component: StatusMappingUpload,
      },
      {
        path: 'removed-vendor-ticket-status-mapping',
        name: 'removedvendorticketstatusmapping',
        props: {
          statusType: 'vendorticketstatus'
        },
        meta: {
          title: 'Removed Vendor Ticket Status Mapping',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedStatusMappingIndex,
      },
      {
        path: 'order-status-mapping',
        name: 'orderstatusmapping',
        props: {
          statusType: 'orderstatus'
        },
        meta: {
          title: 'Order Status Mapping',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-OrderStatusMapping-View'],
        },
        component: StatusMappingIndex,
      },
      {
        path: 'order-status-mapping-details',
        name: 'OrderStatusMapping-Details',
        props: true,
        meta: {
          title: 'Order Status Mapping Details',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-OrderStatusMapping-View'],
        },
        component: StatusMappingDetails,
      },
      {
        path: 'order-status-mapping/:id/edit',
        name: 'OrderStatusMapping-Edit',
        props: true,
        meta: {
          title: 'Order Status Mapping Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-OrderStatusMapping-Edit'],
        },
        component: StatusMappingEdit,
      },
      {
        path: 'order-status-mapping/create',
        name: 'OrderStatusMapping-Create',
        props: {
          statusType: 'orderstatus'
        },
        meta: {
          title: 'Order Status Mapping Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-OrderStatusMapping-Create'],
        },
        component: StatusMappingCreate,
      },
      {
        path: 'order-status-mapping/upload',
        name: 'OrderStatusMapping-Upload',
        props: true,
        meta: {
          title: 'Order Status Mapping Upload',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-OrderStatusMapping-Upload'],
        },
        component: StatusMappingUpload,
      },
      {
        path: 'removed-order-status-mapping',
        name: 'removedorderstatusmapping',
        props: {
          statusType: 'orderstatus'
        },
        meta: {
          title: 'Removed Order Status Mapping',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedStatusMappingIndex,
      },
      {
        path: 'vendors',
        name: 'VendorConfig-Index',
        meta: {
          title: 'Vendor Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Vendors-View'],
        },
        component: VendorConfigIndex,
      },
      {
        path: 'vendors/:id/edit',
        name: 'VendorConfig-Edit',
        props: true,
        meta: {
          title: 'Vendor Config Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Vendors-Edit'],
        },
        component: VendorConfigEdit,
      },
      {
        path: 'vendors/create',
        name: 'VendorConfig-Create',
        props: true,
        meta: {
          title: 'Vendor Config Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Vendors-Create'],
        },
        component: VendorConfigCreate,
      },
      {
        path: 'vendors/removed',
        name: 'RemovedVendorConfig-Index',
        meta: {
          title: 'Removed Vendor Configs',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedVendorConfigIndex,
      },
      {
        path: 'locales',
        name: 'LocaleConfig-Index',
        meta: {
          title: 'Locale Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Locales-View'],
        },
        component: LocaleConfigIndex,
      },
      {
        path: 'locales/:id/edit',
        name: 'LocaleConfig-Edit',
        props: true,
        meta: {
          title: 'Locale Config Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Locales-Edit'],
        },
        component: LocaleConfigEdit,
      },
      {
        path: 'appConfigs',
        name: 'AppConfig-Index',
        meta: {
          title: 'App Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-AppConfig-View'],
        },
        component: AppConfigIndex,
      },
      {
        path: 'appConfigs/:id/create',
        name: 'AppConfig-Create',
        props: true,
        meta: {
          title: 'App Config Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-AppConfig-Create'],
        },
        component: AppConfigCreate,
      },
      {
        path: 'appConfigs/:id/Edit',
        name: 'AppConfig-Edit',
        props: true,
        meta: {
          title: 'App Config Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-AppConfig-Edit'],
        },
        component: AppConfigEdit,
      },
      {
        path: 'roles',
        name: 'Roles-Index',
        meta: {
          title: 'Roles',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Roles-View'],
        },
        component: RolesIndex,
      },
      {
        path: 'roles/create',
        name: 'Roles-Create',
        props: true,
        meta: {
          title: 'Roles Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Roles-Create'],
        },
        component: RolesCreate,
      },
      {
        path: 'roles/:id/Edit',
        name: 'Roles-Edit',
        props: true,
        meta: {
          title: 'Roles Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Roles-Edit'],
        },
        component: RolesEdit,
      },
      {
        path: 'roles/removed',
        name: 'RemovedRoles-Index',
        meta: {
          title: 'Removed Roles',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedRolesIndex,
      },
      {
        path: 'userConfigs',
        name: 'Users-Index',
        props: true,
        meta: {
          title: 'User Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Users-View'],
        },
        component: UserConfigIndex,
      },
      {
        path: 'userConfigs/create',
        name: 'User-Create',
        props: true,
        meta: {
          title: 'User Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Users-Create'],
        },
        component: UserConfigCreate,
      },
      {
        path: 'userConfigs/copy',
        name: 'User-Copy',
        props: true,
        meta: {
          title: 'User Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Users-Create'],
        },
        component: UserConfigCopy,
      },
      {
        path: 'userConfigs/:id/Edit',
        name: 'User-Edit',
        props: true,
        meta: {
          title: 'User Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Users-Edit'],
        },
        component: UserConfigEdit,
      },
      {
        path: 'userConfigs/upload',
        name: 'UserConfigs-Upload',
        props: true,
        meta: {
          title: 'User Configs Upload',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-Users-Upload'],
        },
        component: UserConfigUpload,
      },
      {
        path: 'userConfigs/removed',
        name: 'RemovedUsers-Index',
        props: true,
        meta: {
          title: 'Removed User Configs',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedUserConfigIndex,
      },
      {
        path: 'equipmentExclusionConfigs',
        name: 'EquipmentExclusionConfig-Index',
        meta: {
          title: 'Equipment Exclusion Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-EquipmentExclusion-View'],
        },
        component: EquipmentExclusionConfigIndex,
      },
      {
        path: 'equipmentExclusionConfig/:id/equipmentExclusionItems',
        name: 'EquipmentExclusionItems-Index',
        props: true,
        component: EquipmentExclusionItemsIndex,
        meta: {
          title: 'Equipment Exclusion List Items',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-EquipmentExclusion-View'],
        },
      },
      {
        path: 'equipmentExclusionConfig/Create',
        name: 'EquipmentExclusionConfig-Create',
        props: true,
        meta: {
          title: 'Equipment Exclusion Config',
          requiresAuth: false,
          authorizedPermissions: ['Configuration-EquipmentExclusion-Create'],
        },
        component: EquipmentExclusionConfigCreate,
      },
      {
        path: 'equipmentExclusionConfig/:id/Edit',
        name: 'EquipmentExclusionConfig-Edit',
        props: true,
        meta: {
          title: 'Equipment Exclusion Config Edit',
          requiresAuth: false,
          authorizedPermissions: ['Configuration-EquipmentExclusion-Edit'],
        },
        component: EquipmentExclusionConfigEdit,
      },
      {
        path: 'equipmentExclusionConfigs/removed',
        name: 'RemovedEquipmentExclusionConfig-Index',
        meta: {
          title: 'Removed Equipment Exclusion Configs',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedEquipmentExclusionConfigIndex,
      },
      {
        path: 'equipmentExclusionConfig/:configId/equipmentExclusionItem/:id/Edit',
        name: 'EquipmentExclusionItem-Edit',
        props: true,
        meta: {
          title: 'Equipment Exclusion Item Edit',
          requiresAuth: false,
          authorizedPermissions: ['Configuration-EquipmentExclusion-Edit'],
        },
        component: EquipmentExclusionItemEdit,
      },
      {
        path: 'equipmentExclusionConfig/:configId/equipmentExclusionItem/Create',
        name: 'EquipmentExclusionItem-Create',
        props: true,
        meta: {
          title: 'Equipment Exclusion Item Create',
          requiresAuth: false,
          authorizedPermissions: ['Configuration-EquipmentExclusion-Create'],
        },
        component: EquipmentExclusionItemCreate,
      },
      {
        path: 'equipmentExclusionConfig/:configId/equipmentExclusionItems/upload',
        name: 'EquipmentExclusionItems-Upload',
        props: true,
        meta: {
          title: 'Equipment Exclusion Items Upload',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-EquipmentExclusion-UploadItems'],
        },
        component: EquipmentExclusionItemsUpload,
      },
      {
        path: 'programFees',
        name: 'ProgramFeesConfig-Index',
        meta: {
          title: 'Program Fees',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-ProgramFees-View'],
        },
        component: ProgramFeesConfigIndex,
      },
      {
        path: 'programFees/create',
        name: 'ProgramFeesConfig-Create',
        props: true,
        meta: {
          title: 'Program Fees Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-ProgramFees-Create'],
        },
        component: ProgramFeesConfigCreate,
      },
      {
        path: 'programFees/:id/Edit',
        name: 'ProgramFeesConfig-Edit',
        props: true,
        meta: {
          title: 'Program Fees Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-ProgramFees-Edit'],
        },
        component: ProgramFeesConfigEdit,
      },
      {
        path: 'programFees/removed',
        name: 'RemovedProgramFeesConfig-Index',
        meta: {
          title: 'Removed Program Fees',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedProgramFeesConfigIndex,
      },
      {
        path: 'ancillaryServiceTypeConfigs',
        name: 'AncillaryServiceTypeConfig-Index',
        meta: {
          title: 'Ancillary ServiceType Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-AncillaryServiceType-View'],
        },
        component: AncillaryServiceTypeConfigIndex,
      },
      {
        path: 'ancillaryServiceTypeConfigs/:id/edit',
        name: 'AncillaryServiceTypeConfig-Edit',
        props: true,
        meta: {
          title: 'Ancillary ServiceType Config Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-AncillaryServiceType-Edit'],
        },
        component: AncillaryServiceTypeConfigEdit,
      },
      {
        path: 'ancillaryServiceTypeConfigs/create',
        name: 'AncillaryServiceTypeConfig-Create',
        props: true,
        meta: {
          title: 'Ancillary ServiceType Config Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-AncillaryServiceType-Create'],
        },
        component: AncillaryServiceTypeConfigCreate,
      },
      {
        path: 'serviceNetworkFulfillmentOptionTypeConfigs',
        name: 'ServiceNetworkFulfillmentOptionTypeConfig-Index',
        meta: {
          title: 'ServiceNetwork FulfillmentOption Type Configs',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-ServiceNetworkFulfillmentOptionType-View'],
        },
        component: ServiceNetworkFulfillmentOptionTypeConfigIndex,
      },
      {
        path: 'serviceNetworkFulfillmentOptionTypeConfigs/:id/edit',
        name: 'ServiceNetworkFulfillmentOptionTypeConfig-Edit',
        props: true,
        meta: {
          title: 'ServiceNetwork FulfillmentOption Type Config Edit',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-ServiceNetworkFulfillmentOptionType-Edit'],
        },
        component: ServiceNetworkFulfillmentOptionTypeConfigEdit,
      },
      {
        path: 'serviceNetworkFulfillmentOptionTypeConfigs/create',
        name: 'ServiceNetworkFulfillmentOptionTypeConfig-Create',
        props: true,
        meta: {
          title: 'ServiceNetwork FulfillmentOption Type Config Create',
          requiresAuth: true,
          authorizedPermissions: ['Configuration-ServiceNetworkFulfillmentOptionType-Create'],
        },
        component: ServiceNetworkFulfillmentOptionTypeConfigCreate,
      },
      {
        path: 'serviceNetworkFulfillmentOptionTypeConfigs/removed',
        name: 'RemovedServiceNetworkFulfillmentOptionTypeConfig-Index',
        meta: {
          title: 'Removed ServiceNetwork FulfillmentOption Type Configs',
          requiresAuth: true,
          authorizedPermissions: ['Global-HardDelete'],
        },
        component: RemovedServiceNetworkFulfillmentOptionTypeConfigIndex,
      },
      ...FulfillmentOptionConfigRoutes,
      ...PriceListConfigRoutes,
    ],
    beforeEnter: async (to: any, from: any, next: any) => {
      await store.dispatch(INIT_CONFIG);
      return next();
    },
  },
] as RouteConfig[];

function checkSourceSystemPermission(to: any, from: any, next: any) {
  const sourceSystem = to.params.sourceSystem;
  const requiredPermissions = `SourceSystem-${sourceSystem}`;
  
  if(userHasPermission(to, requiredPermissions)) {
    next();
  } else {
    return next('/denied');
  }
}

function userHasPermission(to: any, requiredPermissions: string) {
  const df = (store.state as any).dynamicfulfillmentmodule as DynamicFulfillmentState;
  return userAuthorizationProviderFactory(df).hasPermission([requiredPermissions]);
}


export default routes;
