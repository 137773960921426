export const INIT_CONFIG = 'initConfig';
export const INIT_USER = 'initUser';
export const INIT_COMPLETED = 'initCompleted';
export const SET_USER_CLAIMS = 'setUserClaims';
//Search
export const SEARCH_AUTH_BY_ID = 'authSearchById';
export const SEARCH_AUTH_BY_FULFILLMENT_TICKET_ID = 'authSearchByFulfillmentTicketId';
export const SEARCH_AUTH_BY_SOURCE_IDENTIFIERS = 'authSearchBySourceIdentifiers';
export const SEARCH_FULFILLMENT_TICKET_BY_AUTH = 'searchFulfillmentTicketByAuth';
export const SEARCH_AUTH_BY_PHONE_NUMBER = 'searchAuthByPhoneNumber';
export const SEARCH_AUTH_BY_KEY_VALUE = 'searchAuthByKeyValue';
export const SEARCH_FULFILLMENT_TICKET_BY_BOOKED_OFFER_CONFIRMATION_ID = 'searchFTByBookedOfferConfirmationId';
//Authorization/Ticket
export const SET_FULFILLMENT_AUTHORIZATION = 'setFulfillmentAuthorization';
export const SET_FULFILLMENT_TICKET = 'setFulfillmentTicket';
export const RESET_AUTH = 'resetAuth';
export const FETCH_FULFILLMENT_TICKET_ORCHESTRATION = 'fetchFulfillmentTicketOrchestration';
export const FETCH_OFFER_SET = 'fetchOfferSet';
export const CANCEL_VENDOR_OFFER = 'cancelVendorOffer';
export const FETCH_SOURCE_SYSTEM_CLAIM = 'fetchSourceSystemClaim';
export const FETCH_VENDOR_STATUS_EVENTS = 'fetchVendorStatusEvents';
export const FETCH_ORDER_STATUS_EVENTS = 'fetchOrderStatusEvents';
export const UPDATE_TICKET_STATUS = 'updateTicketStatus';
export const OVERRIDE_TICKET_STATUS = 'overrideTicketStatus';
export const SEND_VENDOR_TICKET_STATUS = 'sendVendorTicketStatus';
export const UPDATE_TICKET_AUTHORIZATION_AMOUNT = 'updateTicketAuthorizationAmount';
export const RESTART_FULFILLMENT_TICKET_ORCHESTRATION = 'restartFulfillmentTicketOrchestration';
export const FETCH_FULFILLMENTTICKET_INVOICES = 'fetchFulfillmentTicketInvoices';
export const SET_FULFILLMENTTICKET_INVOICES = 'setFulfillmentTicketInvoices';
export const FETCH_SOURCE_SYSTEM_CLAIM_DATA = 'fetchSourceSystemClaimData';
export const FETCH_SOURCE_SYSTEM_MOCK_CLAIM_DATA = 'fetchMockClaimData';
export const UPDATE_SOURCE_SYSTEM_MOCK_CLAIM_DATA = 'updateMockClaimData';
export const DELETE_SOURCE_SYSTEM_MOCK_CLAIM_DATA = 'deleteMockClaimData';
//Configs
export const SET_SOURCE_SYSTEM_CONFIG = 'setSourceSystemConfig';
export const SET_SOURCE_SYSTEM_CONFIGS = 'setSourceSystemConfigs';
export const FETCH_SOURCE_SYSTEM_CONFIG = 'fetchSourceSystemConfig';
export const FETCH_SOURCE_SYSTEM_CONFIGS = 'fetchSourceSystemConfigs';
export const GET_SOURCE_SYSTEM_CONFIGS = 'getSourceSystemConfigs';
export const GET_SOURCE_SYSTEM_CONFIG = 'getSourceSystemConfig';
export const FETCH_FULFILLMENT_OPTION_CONFIGS = 'fetchFulfillmentOptionConfigs';
export const FETCH_FULFILLMENT_OPTION_CONFIG = 'fetchFulfillmentOptionConfig';
export const FETCH_PROGRAM_CONFIGS = 'fetchProgramConfigs';
export const FETCH_SOURCE_SYSTEM_PROGRAM_CONFIGS = 'fetchSourceSystemProgramConfigs';
export const FETCH_PROGRAM_BY_ID_CONFIG = 'fetchProgramConfigById';
export const PROGRAM_CONFIG_SAVE = 'saveProgramConfig';
export const SET_PROGRAM_CONFIGS = 'setProgramConfigs';
export const RESET_PROGRAM_CONFIGS = 'resetProgramConfigs';
export const DELETE_PROGRAM = 'deleteProgram';
export const SET_FULFILLMENT_OPTION_CONFIGS = 'setFulfillmentOptionConfigs';
export const GET_FULFILLMENT_OPTION_CONFIG = 'getFulfillmentOptionConfig';
export const FULFILLMENT_OPTION_CONFIG_SAVE = 'saveFulfillmentOptionConfig';
export const RESET_FULFILLMENT_OPTION_CONFIGS = 'resetFulfillmentOptionConfigs';
export const SAVE_SOURCE_SYSTEM_CONFIG = 'saveSourceSystemConfig';
export const PRODUCTS_FETCH = 'fetchProducts';
export const PRODUCTS_BY_ID_FETCH = 'fetchProductsById';
export const PRODUCTS_SAVE = 'saveProduct';
export const PRODUCTS_DELETE = 'deleteProduct';
export const FETCH_VENDOR_CONFIGS = 'fetchVendorConfigs';
export const FETCH_VENDOR_CONFIG = 'fetchVendorConfig';
export const VENDOR_CONFIG_SAVE = 'saveVendorConfig';
export const VENDOR_CONFIG_DELETE = 'removeVendorConfig';
export const RESET_VENDOR_CONFIGS = 'resetVendorConfigs';
export const SET_VENDOR_CONFIGS = 'setVendorConfigs';
export const FETCH_APPCONFIG_BY_KEY = 'fetchAppConfigByKey';
export const CREATE_APPCONFIG = 'createAppConfig';
export const UPDATE_APPCONFIG = 'updateAppConfig';
export const DELETE_APPCONFIG = 'deleteAppConfig';
export const GET_SVS_ORDER_BY_ID = 'getSvsOrderById';
export const FETCH_LOCALE_CONFIGS = 'getLocaleConfigs';
export const FETCH_LOCALE_CONFIG = 'getLocaleConfig';
export const CREATE_LOCALE_CONFIG = 'createLocaleConfig';
export const DELETE_LOCALE_CONFIG = 'deleteLocaleConfig';
export const UPDATE_LOCALE_CONFIG = 'updateLocaleConfig';
export const SET_LOCALE_CONFIGS = 'setLocaleConfigs';
export const RESET_LOCALE_CONFIGS = 'resetLocaleConfigs';
export const UPDATE_SOURCE_SYSTEM_CONFIG_PARTIAL = 'updateSourceSystemConfigPartial';
export const FETCH_USER_ROLES_PERMISSIONS = 'fetchUserRolesPermissions';
export const GET_USER_ROLES_PERMISSIONS = 'getUserRolesPermissions';
export const SET_USER_ROLES_PERMISSIONS = 'setUserRolesPermissions';
export const FETCH_ROLES = 'fetchRoles';
export const FETCH_ROLE = 'fetchRole';
export const UPDATE_ROLE = 'updateRole';
export const CREATE_ROLE = 'createRole';
export const DELETE_ROLE = 'deleteRole';
export const SET_ROLES = 'setRoles';
export const FETCH_PERMISSIONS = 'fetchPermissions';
export const SET_PERMISSIONS = 'setPermissions';
export const FETCH_USER_CONFIGS = 'fetchUserConfigs';
export const FETCH_USER_CONFIG = 'fetchUserConfig';
export const SET_USER_CONFIGS = 'setUserConfigs';
export const CREATE_USER_CONFIG = 'createUserConfig';
export const DELETE_USER_CONFIG = 'deleteUserConfig';
export const EDIT_USER_CONFIG = 'editUserConfig';
export const FETCH_CONFIG_HISTORY = 'fetchConfigHistory';
export const FETCH_ANCILLARY_SERVICE_TYPE_CONFIGS = 'fetchAncillaryServiceTypeConfigs';
export const FETCH_ANCILLARY_SERVICE_TYPE_CONFIG = 'fetchAncillaryServiceTypeConfig';
export const SET_ANCILLARY_SERVICE_TYPES = 'setAncillaryServiceTypes';
export const RESET_ANCILLARY_SERVICE_TYPES = 'resetAncillaryServiceTypes';
export const UPDATE_ANCILLARY_SERVICE_TYPE_CONFIG = 'updateAncillaryServiceTypeConfig';
export const CREATE_ANCILLARY_SERVICE_TYPE_CONFIG = 'createAncillaryServiceTypeConfig';
export const FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIGS = 'fetchServiceNetworkFulfillmentOptionTypesConfigs';
export const FETCH_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG = 'fetchServiceNetworkFulfillmentOptionTypeConfig';
export const SET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES = 'setServiceNetworkFulfillmentOptionTypes';
export const RESET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES = 'resetServiceNetworkFulfillmentOptionTypes';
export const UPDATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG = 'updateServiceNetworkFulfillmentOptionTypeConfig';
export const CREATE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPE_CONFIG = 'createServiceNetworkFulfillmentOptionTypeConfig';
export const DELETE_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES_CONFIG = 'deleteServiceNetworkFulfillmentOptionTypesConfig';
export const FETCH_PROGRAM_FEES_CONFIGS = 'getProgramFeesConfigs';
export const FETCH_PROGRAM_FEES_CONFIG = 'getProgramFeesConfig';
export const SET_PROGRAM_FEES_CONFIGS = 'setProgramFeesConfigs';
export const CREATE_PROGRAM_FEES_CONFIG = 'createProgramFeesConfig';
export const DELETE_PROGRAM_FEES_CONFIG = 'deleteProgramFeesConfig';
export const EDIT_PROGRAM_FEES_CONFIG = 'editProgramFeesConfig';
export const FETCH_FEE_TYPES = 'getFeeTypes';
//PriceLists
export const FETCH_PRICE_LIST_CONFIG = 'fetchPriceListConfig';
export const FETCH_PRICE_LIST_CONFIGS = 'fetchPriceListConfigs';
export const FETCH_PRICE_LIST_CONFIG_ITEMS = 'fetchPriceListConfigItems';
export const SET_PRICE_LIST_CONFIG = 'setPriceListConfig';
export const SET_PRICE_LIST_CONFIGS = 'setPriceListConfigs';
export const UPLOAD_PRICE_LIST_ITEMS = 'uploadPriceListItems';
export const CLEAR_PRICE_LIST_CONFIGS = 'clearPriceListConfigs';
export const DELETE_PRICE_LIST_CONFIG = 'deletePriceListConfig'
export const GET_PRICE_LIST_CONFIGS = 'getPriceListConfigs';
export const GET_PRICE_LIST_CONFIG = 'getPriceListConfig';
export const GET_FULL_PRICE_LIST_CONFIGS = 'getFullPriceListConfigs';
export const INSERT_PRICE_LIST_CONFIG = 'insertPriceListConfig';
export const UPDATE_PRICE_LIST_CONFIG = 'updatePriceListConfig';
export const FETCH_PRICE_LIST_CONFIG_ITEMS_KEYS = 'fetchPriceListItemsKeys';
export const RESET_PRICE_LIST_ITEMS = 'resetPriceListItems';
export const FETCH_PRICE_LIST_CONFIG_ITEMS_BY_SEARCH_CRITERIA = 'fetchPriceListConfigItemsBySearchCriteria';
//StatusMapping
export const STATUS_MAPPING_FETCH = 'fetchStatusMapping';
export const DELETE_STATUS_MAPPING = 'deleteStatusMapping';
export const STATUS_MAPPING_BY_VENDOR_FULFILLMENTOPTION_FETCH = 'fetchStatusMappingByFulfillmentOption';
export const SET_STATUS_MAPPINGS = 'setStatusMappings';
export const CREATE_STATUS_MAPPING = 'createStatusMapping';
export const RESET_STATUS_MAPPINGS = 'resetStatusMapping';
export const FETCH_STATUS_MAPPING_BY_ID = 'fetchStatusMappingById';
export const SAVE_STATUS_MAPPING = 'saveStatusMapping';
//Widgets
export const FETCH_AUTH_TRACES = 'fetchAuthDiagnosticTraces';
export const FETCH_AUTH_REQUESTS = 'fetchAuthDiagnosticRequests';
export const FETCH_COMMUNICATIONS = 'fetchCommunications';
export const FETCH_LEGACY_CLAIM_DATA = 'fetchLegacyClaimData';
export const FETCH_LEGACY_CALL_DATA = 'fetchLegacyCallData';
export const FETCH_ALL_SUCCESSFUL_AUTH_REQUESTS = 'fetchAllSuccessfulAuthDiagnosticRequests';
export const FETCH_RECENT_AUTHORIZATIONS = 'fetchRecentAuthorizations';
export const FETCH_AI_EXCEPTIONS = 'fetchAppInsightsExceptions';
export const CLEAR_CACHE = 'clearCache';
export const CLEAR_CACHE_BY_KEY = 'clearCacheByKey';
//InventoryStates
export const FETCH_INVENTORY_STATES = 'fetchInventoryStates';
export const REFRESH_INVENTORY_STATES = 'refreshInventoryStates';
export const RESET_INVENTORY_STATES = 'resetInventoryStates';
export const SET_INVENTORY_STATES = 'setInventoryStates';
export const SET_INVENTORY_STATE = 'setInventoryState';
export const ADD_INVENTORY_STATE = 'addInventoryState';
export const SET_INVENTORY_STATES_PAGING_DATA = 'setInventoryStatesPagingData';
export const GET_INVENTORY_STATE = 'getInventoryState';
export const FETCH_INVENTORY_STATE = 'fetchInventoryState';
export const SAVE_INVENTORY_STATE = 'saveInventoryState';
export const UPDATE_INVENTORY_STATE = 'updateInventoryState';
export const DELETE_INVENTORY_STATE = 'deleteInventoryState';
export const FETCH_INVENTORY_PARTS_MAPPING_STATE = 'fetchInventoryPartsMappingState';
export const GET_FULFILLMENTAUTHORIZATION_FILTER_LOG = 'getFulfillmentAuthorizationFilterLog';
export const GET_FULFILLMENTAUTHORIZATION_FILTER_LOGS = 'getFulfillmentAuthorizationFilterLogs';
export const SET_FULFILLMENTAUTHORIZATION_FILTER_LOGS= 'setFulfillmentAuthorizationFilterLogs';
export const INVENTORYSTATES_PAGING_COUNT = '5000';
export const SET_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATES = 'setJustInTimeInventoryPartsMappingStates';
export const FETCH_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATE = 'fetchJustInTimeInventoryPartsMappingState';
//Vendors
export const DELETE_SERVICECENTRAL_CACHE = 'deleteServiceCentralCache';
export const DELETE_SERVICEPOWER_SERVICECENTERS_CACHE = 'deleteServicePowerServiceCentersCache';
export const SERVICECENTRAL_GET_CLAIM = 'serviceCentralGetClaim';
//EquipmentExclusionConfigs
export const FETCH_EQUIPMENT_EXCLUSION_CONFIGS = 'fetchEquipmentExclusionConfigs';
export const FETCH_EQUIPMENT_EXCLUSION_CONFIG = 'fetchEquipmentExclusionConfig';
export const EQUIPMENT_EXCLUSION_CONFIG_DELETE = 'removeEquipmentExclusionConfig';
export const SET_EQUIPMENT_EXCLUSION_CONFIGS = 'setEquipmentExclusionConfigs';
export const FETCH_EQUIPMENT_EXCLUSION_ITEMS = 'fetchEquipmentExclusionItems';
export const FETCH_EQUIPMENT_EXCLUSION_ITEM = 'fetchEquipmentExclusionItem';
export const CREATE_EQUIPMENT_EXCLUSION_ITEM = 'createEquipmentExclusionItem';
export const EDIT_EQUIPMENT_EXCLUSION_ITEM = 'editEquipmentExclusionItem';
export const EQUIPMENT_EXCLUSION_ITEM_DELETE = 'removeEquipmentExclusionItem';
export const CREATE_EQUIPMENT_EXCLUSION_CONFIG = 'createEquipmentExclusionConfig';
export const EDIT_EQUIPMENT_EXCLUSION_CONFIG = 'editEquipmentExclusionConfig';
export const RESET_EQUIPMENT_EXCLUSION_CONFIGS = 'resetEquipmentExclusionConfigs';
export const FETCH_COUNTRY_CONFIGS = 'fetchCountryConfigs';
export const SET_COUNTRY_CONFIGS = 'setCountryConfigs';
export const SEND_ACCESS_REQUEST = 'sendAccessRequest';