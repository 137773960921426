
import Vue from 'vue';
import EventBus from '@/common/event.bus';
import { Component, Prop, Watch } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import ViewContent from '@/components/ViewContent.vue';
import DataTable from '@/components/common/DataTable.vue';
import { PriceListConfig, PriceListItem, PriceListItemsKeys, SearchCriteria } from '@/store/dynamicfulfillment/types';
import { FETCH_PRICE_LIST_CONFIG_ITEMS_BY_SEARCH_CRITERIA, FETCH_PRICE_LIST_CONFIG_ITEMS_KEYS, FETCH_PRICE_LIST_CONFIG } from '@/store/dynamicfulfillment/constants';
import { debounce } from 'lodash';

const namespace: string = 'dynamicfulfillmentmodule';

@Component({ components: { ViewContent, DataTable } })
export default class PriceListConfigItemsIndex extends Vue {
  @Prop({ required: true }) id: string;
  @Action(FETCH_PRICE_LIST_CONFIG_ITEMS_BY_SEARCH_CRITERIA, { namespace }) fetchPriceListConfigItemsBySearchCriteria: (request: { id: string; searchCriteria: SearchCriteria[] }) => Promise<PriceListItem[]>;
  @Action(FETCH_PRICE_LIST_CONFIG_ITEMS_KEYS, { namespace }) fetchPriceListConfigItemsKeys: (configId: string) => Promise<PriceListItemsKeys[]>;
  @Action(FETCH_PRICE_LIST_CONFIG, { namespace }) fetchPriceListConfig: (request: { id: string; route: string, forceFetch: boolean }) => Promise<PriceListConfig>;
  title: string = 'Price List Items';
  priceListItemsKeys: PriceListItemsKeys[] = [];
  filteredItemsKeys: PriceListItemsKeys[] = [];
  priceListItems: PriceListItem[] = [];
  searchCriteria: SearchCriteria[] = [];
  config: PriceListConfig = null;
  isResetting: boolean = false;
  apiRoute: string = 'pricelists';
  searchCriteriaThreshold: number = 250;
  fields: any = [
    { key: 'manufacturer' },
    { key: 'deviceName' },
    { key: 'deviceTypeId', filterable: true },
    { key: 'repairTypeId', filterable: true },
    { key: 'serviceName' },
    { key: 'partsCost' },
    { key: 'laborCost' },
    { key: 'platformFee' },
    { key: 'subTotal' },
    { key: 'maxTaxValue' },
    { key: 'maxTotalAmount' },
    { key: 'vendorDeviceTypeId' },
    { key: 'vendorRepairTypeId' },
    { key: 'modelVariantId' },
    { key: 'effectiveStartDate', sortable: true },
    { key: 'effectiveEndDate', sortable: true },
    { key: 'color' },
    { key: 'damageType', filterable: true },
  ];

  async mounted() {
    this.priceListItemsKeys = await this.fetchPriceListConfigItemsKeys(this.id);
    this.config = await this.fetchPriceListConfig({ id: this.id, route: 'pricelists', forceFetch: false });
  }

  get manufacturers() {
    return this.getFilteredValues('manufacturer', 'deviceName', 'deviceTypeId');
  }
  get deviceNames() {
    return this.getFilteredValues('deviceName', 'manufacturer', 'deviceTypeId');
  }
  get deviceTypeIds() {
    return this.getFilteredValues('deviceTypeId', 'manufacturer', 'deviceName');
  }

  get deviceTypeIdLookupStrategy() {
    return this.config ? this.config.deviceTypeIdLookupStrategy : 'Device Type Id';
  }

  getFilteredValues(targetKey: string, ...filterKeys: string[]): string[] {
    const criteriaValues = filterKeys.map((key) => this.getCriteriaValue(key));
    let filteredItems = this.priceListItemsKeys;

    filterKeys.forEach((key, index) => {
      if (criteriaValues[index]) {
        filteredItems = filteredItems.filter((item: any) => item[key] === criteriaValues[index]);
      }
    });

    const mappedValues = filteredItems.map((item: any) => item[targetKey]);
    return [...new Set(mappedValues)].sort();
  }

  getCriteriaValue(name: string): string {
    const criteria = this.searchCriteria.find((c) => c.name === name);
    return criteria ? criteria.value : '';
  }

  setCriteriaValue(name: string, value: string): void {
    if (this.isResetting) return;

    if (value === null) {
      this.searchCriteria = this.searchCriteria.filter((item) => item.name !== name);
    } else {
      let criteria = this.searchCriteria.find((item) => item.name === name);
      if (criteria) {
        criteria.value = value;
      } else {
        this.searchCriteria.push({ name, value });
      }
    }
  }

  debouncedEvents(events: any) {
    return {
      ...events,
      input: debounce((event: Event) => {
        events.input(event);
      }, 250),
    };
  }

  resetSearch() {
    this.isResetting = true;
    this.searchCriteria = [];
    this.priceListItems = [];
    this.$nextTick(() => {
      this.isResetting = false;
    });
  }

  async search() {
    if (this.searchCriteria.length === 0) {
      EventBus.$emit('e', `A search criteria is required.`);
      return;
    }

    if (this.deviceTypeIds.length > this.searchCriteriaThreshold) {
      EventBus.$emit('e', `Search criteria returned ${this.deviceTypeIds.length} results. Please narrow your search criteria.`);
      return;
    }

    var response = await this.fetchPriceListConfigItemsBySearchCriteria({ id: this.id, searchCriteria: this.searchCriteria });
    Vue.set(this, 'priceListItems', response);
  }

  @Watch('manufacturers', { immediate: true, deep: true })
  private manufacturersChanged(newVal: string, oldVal: string) {
    if (newVal.length === 1) {
      this.setCriteriaValue('manufacturer', newVal[0]);
    }
  }

  @Watch('deviceNames', { immediate: true, deep: true })
  private deviceNamesChanged(newVal: string, oldVal: string) {
    if (newVal.length === 1) {
      this.setCriteriaValue('deviceName', newVal[0]);
    }
  }

  @Watch('deviceTypeIds', { immediate: true, deep: true })
  private deviceTypeIdsChanged(newVal: string, oldVal: string) {
    if (newVal.length === 1) {
      this.setCriteriaValue('deviceTypeId', newVal[0]);
    }
  }
}
