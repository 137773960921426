import { Module } from 'vuex';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { RootState } from '../types';
import {
  DynamicFulfillmentState,
  FulfillmentAuthorization,
  FulfillmentAuthorizationWidget,
  FulfillmentOptionConfig,
  InventoryPartsMappingState,
  InventoryState,
  InventoryStatePage,
  PriceListConfig,
  PriceListItem,
  ProgramConfig,
  SourceSystemConfig,
  VendorConfig,
  FulfillmentAuthorizationSearchPageResult,
  FulfillmentAuthorizationFilterLog,
  VendorTicketStatusMapping,
  LocaleConfig,
  Invoice,
  UserRolesPermissions,
  RoleConfig,
  PermissionsConfig,
  UserConfig,
  EquipmentExclusionConfig,
  OrderStatusMapping,
  AncillaryServiceTypeConfig,
  ProgramFeesConfig,
  CountryConfig,
  ServiceNetworkFulfillmentOptionTypeConfig,
} from './types';
import { UserClaims } from '@/common/auth.types';

export const state: DynamicFulfillmentState = {
  initCompleted: false,
  userClaims: undefined as UserClaims,
  authorizations: {} as { [key: string]: FulfillmentAuthorization },
  sourceSystemConfigs: [] as SourceSystemConfig[],
  programConfigs: [] as ProgramConfig[],
  vendorConfigs: [] as VendorConfig[],
  fulfillmentOptionConfigs: [] as FulfillmentOptionConfig[],
  priceListItems: [] as PriceListItem[],
  fullPriceListConfigs: [] as PriceListConfig[],
  priceListConfigs: null as { [key: string]: PriceListConfig },
  fulfillmentOptionCategories: {
    0: 'Repair',
    1: 'Replace',
    2: 'Reimbursement',
    3: 'Assessment',
  } as { [key: number]: string },
  claimStatuses: {
    0: 'None',
    1: 'Approved',
    2: 'Authorized',
    3: 'Closed',
    4: 'Denied',
    5: 'Open',
  } as { [key: string]: string },
  inventoryStates: [] as InventoryState[],
  inventoryStatePage: {
    inventoryStates: null,
    pagingToken: null,
  } as InventoryStatePage,
  inventoryPartsMappingStates: [] as InventoryPartsMappingState[],
  fulfillmentAuthWidgetRecords: [] as FulfillmentAuthorizationWidget[],
  fulfillmentAuthSearchPageResult: {} as FulfillmentAuthorizationSearchPageResult,
  fulfillmentAuthFilterLogs: [] as FulfillmentAuthorizationFilterLog[],
  vendorTicketStatusMappings: [] as VendorTicketStatusMapping[],
  orderStatusMappings: [] as OrderStatusMapping[],
  localeConfigs: [] as LocaleConfig[],
  fulfillmentTicketInvoices: [] as Invoice[],
  userRolesPermissions: undefined as UserRolesPermissions,
  roles: [] as RoleConfig[],
  permissions: undefined as PermissionsConfig,
  userConfigs: [] as UserConfig[],
  equipmentExclusionConfigs: [] as EquipmentExclusionConfig[],
  ancillaryServiceTypeConfigs: [] as AncillaryServiceTypeConfig[],
  programFeesConfigs: [] as ProgramFeesConfig[],
  countryConfigs: [] as CountryConfig[],
  defaultCountryConfig: {} as CountryConfig,
  serviceNetworkFulfillmentOptionTypeConfigs: [] as ServiceNetworkFulfillmentOptionTypeConfig[],
};

const namespaced: boolean = true;

const dynamicfulfillmentmodule: Module<DynamicFulfillmentState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};

export default dynamicfulfillmentmodule;
