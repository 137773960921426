import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { DynamicFulfillmentState, VendorTicketStatusUpdateRequest, FulfillmentTicket } from '../types';
import * as apis from '../constants';
import { authorizationApiProvider } from '@/common/df.authorization.api';

const VendorApiActions: ActionTree<DynamicFulfillmentState, RootState> = {
  async [apis.SEND_VENDOR_TICKET_STATUS](context, request: VendorTicketStatusUpdateRequest) {
    await authorizationApiProvider.sendVendorTicketStatus(request);
  },

  async [apis.GET_SVS_ORDER_BY_ID](context, id: string) {
    return await authorizationApiProvider.getSvsOrderById(id);
  },

  async [apis.DELETE_SERVICECENTRAL_CACHE](context) {
    return await authorizationApiProvider.deleteServiceCentralCache();
  },

  async [apis.SERVICECENTRAL_GET_CLAIM](context, id: string) {
    return await authorizationApiProvider.getServiceCentralClaimById(id);
  },

  async [apis.DELETE_SERVICEPOWER_SERVICECENTERS_CACHE](context) {
    return await authorizationApiProvider.deleteServicePowerServiceCentersCache();
  },

  async [apis.CANCEL_VENDOR_OFFER](context, ticket: FulfillmentTicket) {
    await authorizationApiProvider.cancelVendorOffer(ticket);
  },
};

export default VendorApiActions;
