import { MutationTree } from 'vuex';
import {
  FulfillmentAuthorization,
  SourceSystemConfig,
  FulfillmentOptionConfig,
  PriceListConfig,
  DynamicFulfillmentState,
  ProgramConfig,
  VendorConfig,
  InventoryState,
  InventoryStatePage,
  FulfillmentAuthorizationFilterLog,
  LocaleConfig,
  Invoice,
  UserRolesPermissions,
  RoleConfig,
  PermissionsConfig,
  UserConfig,
  EquipmentExclusionConfig,
  AncillaryServiceTypeConfig,
  ProgramFeesConfig,
  CountryConfig,
} from './types';
import * as apis from './constants';
import { UserClaims } from '@/common/auth.types';

export const mutations: MutationTree<DynamicFulfillmentState> = {
  [apis.SET_FULFILLMENT_AUTHORIZATION](state, authorization: FulfillmentAuthorization) {
    state.authorizations[authorization.id] = authorization;
  },
  [apis.SET_SOURCE_SYSTEM_CONFIG](state, config: SourceSystemConfig) {
    state.sourceSystemConfigs.splice(state.sourceSystemConfigs.findIndex((c) => c.id === config.id), 1, config);
  },
  [apis.SET_SOURCE_SYSTEM_CONFIGS](state, sourceSystemConfigs: SourceSystemConfig[]) {
    state.sourceSystemConfigs = sourceSystemConfigs;
  },
  [apis.SET_PRICE_LIST_CONFIG](state, {route, config}) {
    if (!state.priceListConfigs) return;
    
    if (state.priceListConfigs[config.id]) {
      state.priceListConfigs[config.id] = config;
    } else {
      Object.assign(state.priceListConfigs, { [config.id]: config });
    }
  },
  [apis.SET_PRICE_LIST_CONFIGS](state, { dictionary, configs, route }) {
    state.priceListConfigs = dictionary;
    state.fullPriceListConfigs = configs;
  },
  [apis.CLEAR_PRICE_LIST_CONFIGS](state, { route }) {
    state.priceListConfigs = null;
    state.fullPriceListConfigs = [];
  },
  async [apis.DELETE_PRICE_LIST_CONFIG](state, priceListConfig: PriceListConfig) {
    delete state.priceListConfigs[priceListConfig.id];
  },
  [apis.SET_FULFILLMENT_OPTION_CONFIGS](state, fulfillmentOptionConfigs: FulfillmentOptionConfig[]) {
    state.fulfillmentOptionConfigs = fulfillmentOptionConfigs;
  },
  [apis.SET_PROGRAM_CONFIGS](state, programConfigs: ProgramConfig[]) {
    state.programConfigs = programConfigs;
  },
  [apis.RESET_PROGRAM_CONFIGS](state) {
    state.programConfigs = [];
  },
  async [apis.DELETE_PROGRAM](state, program: ProgramConfig) {
    const index = state.programConfigs.findIndex((r: ProgramConfig) => r.id === program.id);
    state.programConfigs.splice(index, 1);
  },
  [apis.RESET_FULFILLMENT_OPTION_CONFIGS](state) {
    state.fulfillmentOptionConfigs = [];
  },
  [apis.RESET_VENDOR_CONFIGS](state) {
    state.vendorConfigs = [];
  },
  [apis.RESET_AUTH](state, authorizationId: string) {
    state.authorizations[authorizationId] = null;
  },
  [apis.INIT_COMPLETED](state) {
    state.initCompleted = true;
  },
  [apis.SET_USER_CLAIMS](state, claims: UserClaims) {
    state.userClaims = claims;
  },
  [apis.SET_VENDOR_CONFIGS](state, vendorConfigs: VendorConfig[]) {
    state.vendorConfigs = vendorConfigs;
  },
  [apis.SET_INVENTORY_STATES](state, inventoryStates: InventoryState[]) {
    state.inventoryStates = inventoryStates;
  },
  [apis.SET_INVENTORY_STATES_PAGING_DATA](state, inventoryStatePagingData: InventoryStatePage) {
    state.inventoryStatePage = inventoryStatePagingData;
  },
  [apis.RESET_INVENTORY_STATES](state) {
    state.inventoryStatePage.inventoryStates = [];
  },
  [apis.SET_INVENTORY_STATE](state, inventoryState: InventoryState) {
    const index = state.inventoryStatePage.inventoryStates.findIndex((c) => c.id === inventoryState.id);
    state.inventoryStatePage.inventoryStates[index] = inventoryState;
  },
  [apis.ADD_INVENTORY_STATE](state, inventoryState: InventoryState) {
    state.inventoryStatePage.inventoryStates.unshift(inventoryState);
  },
  [apis.UPDATE_INVENTORY_STATE](state, inventoryState: InventoryState) {
    const index = state.inventoryStatePage?.inventoryStates?.findIndex((c) => c.id === inventoryState.id);
    if (index && index >= 0) {
      state.inventoryStatePage.inventoryStates.splice(index, 1, inventoryState);
    }
  },
  [apis.DELETE_INVENTORY_STATE](state, id: string) {
    const index = state.inventoryStatePage.inventoryStates.findIndex((c) => c.id === id);
    state.inventoryStatePage.inventoryStates.splice(index, 1);
  },
  [apis.SET_FULFILLMENTAUTHORIZATION_FILTER_LOGS](state, logs: FulfillmentAuthorizationFilterLog[]) {
    state.fulfillmentAuthFilterLogs = logs;
  },
  [apis.SET_STATUS_MAPPINGS](state, params: {apiRoute: string, statusMappings: any[]}) {
    switch (params.apiRoute) {
      case 'vendorticketstatus':
        state.vendorTicketStatusMappings = params.statusMappings;
        break;
      case 'orderstatus':
        state.orderStatusMappings = params.statusMappings;
        break;
    }    
  },
  [apis.RESET_STATUS_MAPPINGS](state, apiRoute: string) {
    switch(apiRoute) {
      case 'vendorticketstatus':
        state.vendorTicketStatusMappings = [];
        break;
      case 'orderStatus':
        state.orderStatusMappings = [];
        break;
    }    
  },
  [apis.SET_LOCALE_CONFIGS](state, localeConfigs: LocaleConfig[]) {
    state.localeConfigs = localeConfigs;
  },
  [apis.RESET_LOCALE_CONFIGS](state) {
    state.localeConfigs = [];
  },
  [apis.SET_FULFILLMENTTICKET_INVOICES](state, invoices: Invoice[]) {
    state.fulfillmentTicketInvoices = invoices;
  },
  [apis.SET_USER_ROLES_PERMISSIONS](state, userRolesPermissions: UserRolesPermissions) {
    state.userRolesPermissions = userRolesPermissions;
  },
  [apis.SET_ROLES](state, roles: RoleConfig[]) {
    state.roles = roles;
  },
  [apis.SET_PERMISSIONS](state, permissions: PermissionsConfig) {
    state.permissions = permissions;
  },
  async [apis.DELETE_ROLE](state, role: RoleConfig) {
    const index = state.roles.findIndex((r: RoleConfig) => r.id === role.id);
    state.roles.splice(index, 1);
  },
  async [apis.SET_USER_CONFIGS](state, userConfigs: UserConfig[]) {
    state.userConfigs = userConfigs;
  },
  async [apis.DELETE_USER_CONFIG](state, user: UserConfig) {
    const index = state.userConfigs.findIndex((u: UserConfig) => u.id === user.id);
    state.userConfigs.splice(index, 1);
  },
  async [apis.SET_EQUIPMENT_EXCLUSION_CONFIGS](state, equipmentExclusionListConfigs: EquipmentExclusionConfig[]) {
    state.equipmentExclusionConfigs = equipmentExclusionListConfigs;
  },
  async [apis.EQUIPMENT_EXCLUSION_CONFIG_DELETE](state, equipmentExclusionListConfig: EquipmentExclusionConfig) {
    const index = state.equipmentExclusionConfigs.findIndex((u: EquipmentExclusionConfig) => u.id === equipmentExclusionListConfig.id);
    state.equipmentExclusionConfigs.splice(index, 1);
  },
  [apis.RESET_EQUIPMENT_EXCLUSION_CONFIGS](state) {
    state.equipmentExclusionConfigs = [];
  },
  [apis.SET_ANCILLARY_SERVICE_TYPES](state, ancillaryServiceTypeConfigs: AncillaryServiceTypeConfig[]) {
    state.ancillaryServiceTypeConfigs = ancillaryServiceTypeConfigs;
  },
  [apis.RESET_ANCILLARY_SERVICE_TYPES](state) {
    state.ancillaryServiceTypeConfigs = [];
  },
  [apis.RESET_SERVICE_NETWORK_FULFILLMENT_OPTION_TYPES](state) {
    state.serviceNetworkFulfillmentOptionTypeConfigs = [];
  },
  async [apis.SET_PROGRAM_FEES_CONFIGS](state, programFeesConfigs: ProgramFeesConfig[]) {
    state.programFeesConfigs = programFeesConfigs;
  },
  async [apis.DELETE_PROGRAM_FEES_CONFIG](state, programFeeConfig: ProgramFeesConfig) {
    const index = state.programFeesConfigs.findIndex((u: ProgramFeesConfig) => u.id === programFeeConfig.id);
    state.programFeesConfigs.splice(index, 1);
  },
  async [apis.SET_COUNTRY_CONFIGS](state, countryConfigs: CountryConfig[]) {
    state.countryConfigs = countryConfigs;
    let defaultConfig = countryConfigs.find((c) => c.countryCodeIso2.toLowerCase() === process.env.VUE_APP_REGION.toLowerCase() || c.countryCodeIso3.toLowerCase() === process.env.VUE_APP_REGION.toLowerCase());
    if(!defaultConfig && process.env.VUE_APP_REGION.toLowerCase() === 'uk') {
      defaultConfig = countryConfigs.find((c) => c.countryCodeIso2.toLowerCase() === 'gb' || c.countryCodeIso3.toLowerCase() === 'gbr');
    }
    state.defaultCountryConfig = defaultConfig ? defaultConfig : countryConfigs[0];
  },  
};
