export const INIT_CONFIG = 'dynamicfulfillmentmodule/initConfig';
export const INIT_USER = 'dynamicfulfillmentmodule/initUser';
export const FETCH_SOURCE_SYSTEM_CONFIGS = 'dynamicfulfillmentmodule/fetchSourceSystemConfigs';
export const SAVE_SOURCE_SYSTEM_CONFIG = 'dynamicfulfillmentmodule/saveSourceSystemConfig';
export const FETCH_FULFILLMENT_OPTION_CONFIG = 'dynamicfulfillmentmodule/fetchFulfillmentOptionConfig';
export const FULFILLMENT_OPTION_CONFIG_SAVE = 'dynamicfulfillmentmodule/saveFulfillmentOptionConfig';
export const FETCH_PROGRAM_CONFIGS = 'dynamicfulfillmentmodule/fetchProgramConfigs';
export const FETCH_SOURCE_SYSTEM_PROGRAM_CONFIGS = 'dynamicfulfillmentmodule/fetchSourceSystemProgramConfigs';
export const FETCH_PROGRAM_BY_ID_CONFIG = 'dynamicfulfillmentmodule/fetchProgramConfigById';
export const PROGRAM_CONFIG_SAVE = 'dynamicfulfillmentmodule/saveProgramConfig';
export const DELETE_PROGRAM = 'dynamicfulfillmentmodule/deleteProgram';
export const PRODUCTS_FETCH = 'dynamicfulfillmentmodule/fetchProducts';
export const PRODUCTS_BY_ID_FETCH = 'dynamicfulfillmentmodule/fetchProductsById';
export const PRODUCTS_SAVE = 'dynamicfulfillmentmodule/saveProduct';
export const PRODUCTS_DELETE = 'dynamicfulfillmentmodule/deleteProduct';
export const STATUS_MAPPING_FETCH = 'dynamicfulfillmentmodule/fetchStatusMapping';
export const DELETE_STATUS_MAPPING = 'dynamicfulfillmentmodule/deleteStatusMapping';
export const STATUS_MAPPING_BY_VENDOR_FULFILLMENTOPTION_FETCH = 'dynamicfulfillmentmodule/fetchStatusMappingByFulfillmentOption';
export const SET_STATUS_MAPPINGS = 'dynamicfulfillmentmodule/setStatusMappings';
export const CREATE_STATUS_MAPPING = 'dynamicfulfillmentmodule/createStatusMapping';
export const RESET_STATUS_MAPPINGS = 'dynamicfulfillmentmodule/resetStatusMapping';
export const FETCH_STATUS_MAPPING_BY_ID = 'dynamicfulfillmentmodule/fetchStatusMappingById';
export const SAVE_STATUS_MAPPING = 'dynamicfulfillmentmodule/saveStatusMapping';
export const FETCH_VENDOR_CONFIGS = 'dynamicfulfillmentmodule/fetchVendorConfigs';
export const FETCH_VENDOR_CONFIG = 'dynamicfulfillmentmodule/fetchVendorConfig';
export const VENDOR_CONFIG_SAVE = 'dynamicfulfillmentmodule/saveVendorConfig';
export const VENDOR_CONFIG_DELETE = 'dynamicfulfillmentmodule/removeVendorConfig';
export const FETCH_INVENTORY_STATES = 'dynamicfulfillmentmodule/fetchInventoryStates';
export const REFRESH_INVENTORY_STATES = 'dynamicfulfillmentmodule/refreshInventoryStates';
export const FETCH_INVENTORY_STATE = 'dynamicfulfillmentmodule/fetchInventoryState';
export const SAVE_INVENTORY_STATE = 'dynamicfulfillmentmodule/saveInventoryState';
export const UPDATE_INVENTORY_STATE = 'dynamicfulfillmentmodule/updateInventoryState';
export const SET_INVENTORY_STATE = 'dynamicfulfillmentmodule/setInventoryState';
export const DELETE_INVENTORY_STATE = 'dynamicfulfillmentmodule/deleteInventoryState';
export const FETCH_MIGRATION_MATRIX_DATA = 'globalmodule/fetchMigrationMatrixData';
export const FETCH_ALL_MIGRATION_MATRIX_DATA = 'globalmodule/fetchAllMigrationMatrixData';
export const FETCH_INVENTORY_PARTS_MAPPING_STATE = 'dynamicfulfillmentmodule/fetchInventoryPartsMappingState';
export const FETCH_JUSTINTIME_INVENTORY_PARTS_MAPPING_STATE = 'dynamicfulfillmentmodule/fetchJustInTimeInventoryPartsMappingState';
export const FETCH_APPCONFIG_BY_KEY = 'dynamicfulfillmentmodule/fetchAppConfigByKey';
export const FETCH_LOCALE_CONFIGS = 'dynamicfulfillmentmodule/getLocaleConfigs';
export const FETCH_LOCALE_CONFIG = 'dynamicfulfillmentmodule/getLocaleConfig';
export const CREATE_LOCALE_CONFIG = 'dynamicfulfillmentmodule/createLocaleConfig';
export const DELETE_LOCALE_CONFIG = 'dynamicfulfillmentmodule/deleteLocaleConfig';
export const UPDATE_LOCALE_CONFIG = 'dynamicfulfillmentmodule/updateLocaleConfig';
export const SET_LOCALE_CONFIGS = 'dynamicfulfillmentmodule/setLocaleConfigs';
export const RESET_LOCALE_CONFIGS = 'dynamicfulfillmentmodule/resetLocaleConfigs';
export const FETCH_FULFILLMENT_OPTION_CONFIGS = 'dynamicfulfillmentmodule/fetchFulfillmentOptionConfigs';
export const SAVE_SERVICECENTER_LOCATION = 'globalmodule/saveServiceCenterLocation';
export const SET_SERVICECENTER_LOCATION_STATE = 'globalmodule/setServiceCenterLocationState';
export const FETCH_SERVICECENTER_STATE = 'globalmodule/fetchServiceCenterState';
export const RESET_FULFILLMENT_OPTION_CONFIGS = 'dynamicfulfillmentmodule/resetFulfillmentOptionConfigs';
export const SAVE_SERVICE_MANUFACTURER = 'globalmodule/saveServiceManufacturer';
export const UPDATE_SERVICE_MANUFACTURER = 'globalmodule/updateServiceManufacturer';
export const RESET_SERVICE_MANUFACTURERS = 'globalmodule/resetServiceManufacturers';
export const FETCH_SERVICE_MANUFACTURERS = 'globalmodule/fetchServiceManufacturers';
export const FETCH_SERVICE_MANUFACTURER = 'globalmodule/fetchServiceManufacturer';
export const SET_SERVICE_MANUFACTURERS = 'globalmodule/setServiceManufacturers';
export const FETCH_EQUIPMENT_EXCLUSION_CONFIGS = 'dynamicfulfillmentmodule/fetchEquipmentExclusionConfigs';
export const FETCH_EQUIPMENT_EXCLUSION_CONFIG = 'dynamicfulfillmentmodule/fetchEquipmentExclusionConfig';
export const EQUIPMENT_EXCLUSION_CONFIG_DELETE = 'dynamicfulfillmentmodule/removeEquipmentExclusionConfig';
export const FETCH_EQUIPMENT_EXCLUSION_ITEMS = 'dynamicfulfillmentmodule/fetchEquipmentExclusionItems';
export const FETCH_EQUIPMENT_EXCLUSION_ITEM = 'dynamicfulfillmentmodule/fetchEquipmentExclusionItem';
export const EQUIPMENT_EXCLUSION_ITEM_DELETE = 'dynamicfulfillmentmodule/removeEquipmentExclusionItem';
export const FETCH_CONFIG_HISTORY = 'dynamicfulfillmentmodule/fetchConfigHistory';
export const EDIT_EQUIPMENT_EXCLUSION_CONFIG = 'dynamicfulfillmentmodule/editEquipmentExclusionConfig';
export const EDIT_EQUIPMENT_EXCLUSION_ITEM = 'dynamicfulfillmentmodule/editEquipmentExclusionItem';
export const UPDATE_ROLE = 'dynamicfulfillmentmodule/updateRole';
export const EDIT_USER_CONFIG = 'dynamicfulfillmentmodule/editUserConfig';
export const UPDATE_PRICE_LIST_CONFIG = 'dynamicfulfillmentmodule/updatePriceListConfig';
export const FETCH_COUNTRY_CONFIGS='dynamicfulfillmentmodule/fetchCountryConfigs';